import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from '../components/Layout/layout';
import DownloadSection from "../components/DownloadSection";
import PageHero from "../components/PageHero";
import { Seo, SEO } from "../components/Seo/seo";

const PrivacyPolicy = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulPageLayout(
        filter: {id: {eq: "b6a837d4-743d-5f74-b6cc-56c2781370eb"}}
      ) {
      edges {
        node {
          id
          name
          title
          pageDescription
          image {
            url
          }
          content {
            ... on ContentfulPageHero {
              id
              name
              bannerImage {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  quality: 100
                  width: 1440
                )
              }
              bannerText {
                raw
              }
              smallBannerImage {
                gatsbyImageData(
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                  width: 950
                  quality: 100
                )
              }
            }
            ... on ContentfulDownloadSection {
              id
              name
              sectionTitle {
                raw
              }
              files {
                filename
                publicUrl
                placeholderUrl
                title
                url
              }
            }
          }
        }
      }
    }
    allContentfulLocation {
      edges {
        node {
          phoneNumber
        }
      }
    }
  }
  `)


  return (
    <Layout>
      <PageHero data={data.allContentfulPageLayout.edges[0].node.content[0]} />
      <DownloadSection data={data.allContentfulPageLayout.edges[0].node.content[1]} />
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Privacy Policy" 
    description="TGL Med Staff provides the highest quality support for its travelers."
    pathname="/privacy-policy"
  
  />

)

export default PrivacyPolicy
