import * as React from 'react';

import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { StaticImage } from 'gatsby-plugin-image';

import { downloads, downloads_title, downloads_file} from './download-section.module.scss';

const DownloadSection = ({data}) => {

    return (
        <section className={downloads}>
            <div>
                <div className={downloads_title}>
                    {renderRichText(data.sectionTitle)}
                </div>
                <div>
                    <ul>
                        {data.files.map((file, index) =>
                        <li key={`pdf-${index}`} className={downloads_file}>
                            <a download={file.publicUrl} href={file.publicUrl} title={file.filename}>
                                <StaticImage src="../../assets/images/icons/pdf-icon.png" width="150" alt="pdf icon" />
                                <h3>{file.title}</h3>
                            </a>
                        </li>
                        )}
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default DownloadSection